import React from "react";
import './Login.css'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import baseUrl from '../api'


function LoginHeader(props) {

    const [auth, setAuth] = useState(getAuth());
    const [shouldShowSignUp, setShouldShowSignUp] = useState(false);

    const toggleSignUp = () => {
      setShouldShowSignUp(!shouldShowSignUp);
    }
    const [email, setEmail] = useState('');
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    }

    const checkEmailDomain = (email) => {
      // Define the allowed domain(s) as an array
      // const allowedDomains = ["volunteer.heartfulness.org"];
  
      // // Extract the domain from the email
      // const emailDomain = email.split("@")[1];
  
      // // Check if the email domain is in the allowed domains list
      // if (allowedDomains.includes(emailDomain)) {
      //   return true;
      // }
      // return false;

      return true;
  };

    function signUpUser(user) {

      if(!checkEmailDomain(user.email)) {
        alert('Please use your volunteer.heartfulness.org email address when signing up with google');
        return;
      }

      const url = baseUrl + '/api/users/signup';
      const headers = new Headers();
      headers.append('uid', user.email);
      headers.append('nuid', user.uid);
  
      const requestOptions = {
        method: 'POST',
        headers: headers
      };
  
      fetch(url, requestOptions)
        .then(response => {
          if (response.status === 401) {
            alert('Please contact the admin to add your email to the portal.');
            return null;
          }
          return response.json();
        })
        .then(data => {
          if(data == null) {
            return;
          }
          alert('You have been successfully registered.');
          props.didTapLogin(user);
        })
        .catch(error => {
          console.error('There was a problem signing up. Please try again later.', error);
        });
      
    }

    function signInWithGoogle() {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
          .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            
            // The signed-in user info.
            const user = result.user;
            props.didTapLogin(user);
          }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
            console.error(error);
          });
    }

    function signUpWithGoogle() {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          
          // The signed-in user info.
          const user = result.user;
          signUpUser(user);

        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
          console.error(error);
        });
  }

    return (
        <div className='loginHeader'>
            <img id='loginHeaderImage' src='https://s3-us-west-2.amazonaws.com/heartfulnessweb/en/wp-content/uploads/2019/04/05114150/LTM-logo_updated.png'></img>
            <button className='login-with-google-btn'onClick={signInWithGoogle}>Sign In</button>
            <button className="signUpButton" onClick={signUpWithGoogle}>First time using this website? Click here to sign-up</button>
      
        <br></br>
        <br></br>
            <p id='loginHeaderMessage'>Please login/sign-up using your volunteer.heartfulness account</p>
        </div>
    );
}

export default LoginHeader;