import React, { useState } from 'react';
import LoginHeader from './Login/Login.js'
import AdminView from './Admin/Admin.js';
import './App.css'
import AvailableSlotsView from './Home/AvailableSlots.js'
import MyMeetings from './MyMeetings/MyMeetings.js'
import { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import baseUrl from './api.js';

function App(props) {
    
    const [ tab, setTab ] = useState('home');
    const [user, setCurrentUser] = useState(null);
    const [auth, setAuth] = useState(null);

    const checkEmailDomain = (email) => {
        // Define the allowed domain(s) as an array
        const allowedDomains = ["volunteer.heartfulness.org"];
    
        // Extract the domain from the email
        const emailDomain = email.split("@")[1];
    
        // Check if the email domain is in the allowed domains list
        if (allowedDomains.includes(emailDomain)) {
          return true;
        }
        return false;
    };

  useEffect(() => {
    const auth = getAuth();
    setAuth(auth);
    const savedUser = localStorage.getItem('user');
        if (savedUser) {
            setCurrentUser(JSON.parse(savedUser));
        }
    return;
  }, []);

    function didTapLogin(user) {
        if(user != null) {
            getAbhyasi(user);
        }
    }

    const logOut = () => {
        auth.signOut();
        setCurrentUser(null);
        localStorage.removeItem('user');
    };

    function switchTabToHome() {
        setTab('home');
    }

    function switchTabToMyMeetings() {
        setTab('mymeetings');
    }

    function switchTabToAdmin() {
        setTab('admin');
    }

    function getAbhyasi(user) {
        
        const url = `${baseUrl}/api/users/me`; // Use backticks for template literals
        const headers = new Headers();
    
        // Add headers to the headers object
        headers.append('X-API-KEY', ",PRGojtX9`c4e24219-fb95-;'.4ed8392hfds';");
        console.log('local id', user.localId);
        console.log('uid', user.uid);
        headers.append('uid', user.uid);

        const requestOptions = {
            method: 'GET',
            headers: headers
        };
    
        fetch(url, requestOptions)
            .then(response => {
                // check if the response is a 404
                if (response.status === 404) {
                    // if it is, then throw an error
                    alert('You are not a registered user. Please contact the admin.');
                    return;
                }
                if(response.status == 401) {
                    alert('You are not authorized user. Please try to sign-up');
                    return;
                }
                return response.json();
            })
            .then(data => {

                user = {
                    "uid" : data.uid,
                    "displayName" : user.displayName,
                    "email" : user.email,
                    "role" : data.role
                }

                setCurrentUser(user);
                localStorage.setItem('user', JSON.stringify(user));
            })
            .catch(error => {
                // Handle any errors that occurred during the fetch
                console.error('There was a problem logging in. Please try again later.', error);
            });
    }

    return (
        <div>
            <header className="site-header">
                <div className="site-identity">
                    <img className='hfn-icon' src='https://s3-us-west-2.amazonaws.com/heartfulnessweb/en/wp-content/uploads/2019/04/05114150/LTM-logo_updated.png' alt='Logo'></img>
                    <h4>Heartfulness Zoom Meeting Scheduler</h4>
                </div>
                {
                    user ? 
                    (
                        <nav className="site-navigation">        
                            <ul className="nav">
                                <li onClick={switchTabToHome}
                                    style={
                                        tab === 'home' ? {borderBottom: '1.5px solid black'} : {borderBottom: 'none'}
                                    }>
                                    <button>My Meetings</button>
                                </li>
                                <li onClick={switchTabToMyMeetings} 
                                    style={
                                        tab === 'mymeetings' ? {borderBottom: '1.5px solid black'} : {borderBottom: 'none'}
                                    }>
                                    <button>Available Slots</button>
                                </li>
                                {/* Conditionally render Admin tab */}
                                {user.role === 'ADMIN' && (
                                    <li onClick={switchTabToAdmin}
                                        style={
                                            tab === 'admin' ? {borderBottom: '1.5px solid black'} : {borderBottom: 'none'}
                                        }>
                                        <button>Admin</button>
                                    </li>
                                )}
                                <li onClick={logOut}><button>Logout</button></li>
                            </ul>
                        </nav>
                    )
                    : (
                        <div></div>
                    )
                }        
            </header>
            {user ? (
                tab === 'home' ? (
                    <MyMeetings profile={user}/>
                ) : tab === 'mymeetings' ? (
                    <AvailableSlotsView profile={user}/>
                ) : tab === 'admin' && user.role === 'ADMIN' ? (
                    // Replace AdminPanel with your admin component
                    <AdminView profile={user}/>
                ) : (
                    <div></div>
                )
            ) : (
                <LoginHeader didTapLogin={didTapLogin}/>
            )}
        </div>
    );
}
export default App;
