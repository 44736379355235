import './Admin.css'
import React, { useEffect } from 'react'
import {Modal, Button, Form} from 'react-bootstrap'
import DatePicker from "react-datepicker";
import { useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Spinner from 'react-bootstrap/Spinner';
import baseUrl from '../api'
import { set } from 'firebase/database';


function AdminView(props) {
    return (
        <div id='viewContainer'>
            <div id='welcomeContainer'>
                <h3>Admin</h3>
            </div>
            <div className='formContainer'>
                <AddUserForm profile={props.profile}/>
                <AddZoomUserForm profile={props.profile}/>
            </div>
            <div id='usersContainer'>
                <AllZoomUsersList profile={props.profile} />
                <AllUsersList profile={props.profile} />
            </div>
        </div>
    );
}

function AddUserForm(props) {

    // New states for the new fields
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('BASIC'); // default to 'basic'
    const [loading, setLoading] = useState(false);

    // Handlers for the new fields
    const handleFirstNameChange = (e) => setFirstName(e.target.value);
    const handleLastNameChange = (e) => setLastName(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handleRoleChange = (e) => setRole(e.target.value);

    function addUser() {

        setLoading(true);

        if(firstName === '') {
            alert('Please enter a first name.');
            return;
        }
        if(lastName === '') {
            alert('Please enter a last name.');
            return;
        }
        if(email === '') {
            alert('Please enter an email.');
            return;
        }

        const url = baseUrl + '/api/users/';
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('uid', props.profile.uid);
        
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                "uid": email,
                "firstName": firstName,
                "lastName": lastName,
                "email": email,
                "role": role
            })
        };

        fetch(url, requestOptions)
            .then(response => {
                setLoading(false); // Reset loading state
                if (response.status === 201) {
                    alert('User added successfully.');
                } else {
                    alert('Error adding user.');
                }
            })
            .catch(error => {
                setLoading(false); // Reset loading state
                console.log(error);
            });
    }

    return (
        <div className="addUserForm">
            <Form>
                <h3>Add User</h3>

                <Form.Group controlId="firstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter first name"
                        value={firstName}
                        onChange={handleFirstNameChange}
                    />
                </Form.Group>

                <Form.Group controlId="lastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter last name"
                        value={lastName}
                        onChange={handleLastNameChange}
                    />
                </Form.Group>

                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                </Form.Group>

                <fieldset>
                    <Form.Group>
                        <Form.Label as="legend">Role</Form.Label>
                        <Form.Check
                            type="radio"
                            label="Basic"
                            name="roleOptions"
                            id="roleBasic"
                            value="BASIC"
                            checked={role === 'BASIC'}
                            onChange={handleRoleChange}
                        />
                        <Form.Check
                            type="radio"
                            label="Admin"
                            name="roleOptions"
                            id="roleAdmin"
                            value="ADMIN"
                            checked={role === 'ADMIN'}
                            onChange={handleRoleChange}
                        />
                    </Form.Group>
                </fieldset>
            </Form>
            <Button className='saveButton' variant="dark" onClick={addUser}>
                Save
            </Button>
            <Spinner id='spinner' animation="border" role="status" hidden={!loading}> </Spinner>
        </div>
    );
} 

function AddZoomUserForm(props) {


    const [loading, setLoading] = useState(false);


    // States for the new fields
    const [participantLimit, setParticipantLimit] = useState('100'); // default to '100'
    const [customParticipantLimit, setCustomParticipantLimit] = useState(''); // for custom input
    const [email, setEmail] = useState('');

    // Handlers for the new fields
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handleParticipantLimitChange = (e) => {
        setParticipantLimit(e.target.value);
        if (e.target.value !== 'custom') {
            setCustomParticipantLimit(''); // Reset custom limit if not selected
        }
    };
    const handleCustomParticipantLimitChange = (e) => setCustomParticipantLimit(e.target.value);


    function addZoomUser() {
        const url = baseUrl + '/api/zoomusers/';
        const headers = new Headers();
        headers.append('uid', props.profile.uid);
        headers.append('Content-Type', 'application/json');
        setLoading(true);

        if(email === '') {
            alert('Please enter an email.');
            return;
        }
        if(participantLimit === 'custom' && customParticipantLimit === '') {
            alert('Please enter a custom participant limit.');
            return;
        }

        // participant limit in integer
        const participantLimitInt = participantLimit === 'custom' ? parseInt(customParticipantLimit) : parseInt(participantLimit);
        
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                "zoomUserId": "",
                "email": email,
                "participantLimit": participantLimitInt
            })
        };

        fetch(url, requestOptions)
            .then(response => {
                setLoading(false); // Reset loading state
                if (response.status === 201) {
                    alert('Zoom user added successfully.');
                } else {
                    alert('Error adding Zoom user.');
                }
            })
            .catch(error => {
                setLoading(false); // Reset loading state
                console.log(error);
            });
    }
    
    return (
        <div className="addUserForm">

            <Form >
                <h3>Add Zoom User</h3>

                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                </Form.Group>

                {/* Participant Limit Radio Buttons */}
                <fieldset>
                    <Form.Group>
                        <Form.Label as="legend">Participant Limit</Form.Label>
                        {['100', '300', '500', 'custom'].map((limit) => (
                            <Form.Check
                                key={limit}
                                type="radio"
                                label={limit === 'custom' ? 'Custom' : limit}
                                name="participantLimitOptions"
                                id={`participantLimit${limit}`}
                                value={limit}
                                checked={participantLimit === limit}
                                onChange={handleParticipantLimitChange}
                            />
                        ))}
                    </Form.Group>
                </fieldset>

                {/* Custom Participant Limit Input */}
                {participantLimit === 'custom' && (
                    <Form.Group controlId="customParticipantLimit">
                        <Form.Label>Custom Participant Limit</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter custom limit"
                            value={customParticipantLimit}
                            onChange={handleCustomParticipantLimitChange}
                        />
                    </Form.Group>
                )}
            </Form>

            <Button className='saveButton' variant="dark" onClick={addZoomUser}>
                Save
            </Button>
            <Spinner id='spinner' animation="border" role="status" hidden={!loading}> </Spinner>        
        </div>
        
    );
}

function AllZoomUsersList(props) {

    let url = baseUrl + '/api/zoomusers/';
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    function deleteUser(userId) {

        // show alert to confirm deletion
        if (!window.confirm('Are you sure you want to delete this user?')) {
            return;
        }

        const deleteUrl = baseUrl + '/api/zoomusers/' + userId
        setLoading(true);
        const requestOptions = {
          method: 'DELETE',
          headers: {
            'uid': props.profile.uid,
            'Content-Type': 'application/json',
          },
        };
    
        fetch(deleteUrl, requestOptions)
          .then(response => {
            if (response.ok) {
              // Remove the deleted user from the state
              setUsers(prevUsers => prevUsers.filter(user => user.zoomUserId !== userId));
              alert('User deleted successfully.');
            } else {
              throw new Error('Failed to delete user');
            }

            setLoading(false);
            
          })
          .catch(error => {
            setError(error);
            setLoading(false);
            alert('Failed to delete user');
          });
      };

    // fetch all users from the API once
    useEffect(() => {
        const headers = new Headers();
        headers.append('uid', props.profile.uid);
        headers.append('Content-Type', 'application/json');

        const requestOptions = {
            method: 'GET',
            headers: headers,
        }

        setLoading(true);
        
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setUsers(data);
                console.log(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }
    , [url]);

    return (
        <div className='allZoomUsersTable'>
    <h3>All Zoom Users</h3>
    <table>
        <thead>
            <tr>
                <th>S.no</th>
                <th>Email</th>
                <th>Participant Count</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {users.map((user, index) => (
                <tr key={user.zoomUserId}>
                    <td>{index + 1}</td>
                    <td>{user.email}</td>
                    <td>{user.participantLimit}</td>
                    <td>
                        <button
                        className="btn btn-danger btn-sm"
                         onClick={() => deleteUser(user.zoomUserId)}>Delete</button>
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
</div>


    );
}

function AllUsersList(props) {
    
        let url = baseUrl + '/api/users/';
        const [users, setUsers] = useState([]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(null);
    
        // fetch all users from the API once
        useEffect(() => {
            const headers = new Headers();
            headers.append('uid', props.profile.uid);
            headers.append('Content-Type', 'application/json');
    
            const requestOptions = {
                method: 'GET',
                headers: headers,
            }
    
            setLoading(true);
            
            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setUsers(data);
                    console.log(data);
                    setLoading(false);
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                });
        }
        , [url]);
    
        return (
            <div className='allUsersTable'>
        <h3>All Users</h3>
        <table>
            <thead>
                <tr>
                    <th>S.no</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Role</th>
                </tr>
            </thead>
            <tbody>
                {users.map((user, index) => (
                    <tr key={user.uid}>
                        <td>{index + 1}</td>
                        <td>{user.firstName}</td>
                        <td>{user.lastName}</td>
                        <td>{user.email}</td>
                        <td>{user.role}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
    
    
        );
}

export default AdminView;
