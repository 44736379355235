import FullCalendar from "@fullcalendar/react";
import listPlugin from '@fullcalendar/list';
import React, { useMemo } from "react";
import { useState, useRef } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Modal, Button, Form, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import './MyMeeting.css'
import baseUrl from '../api'
import moment from 'moment-timezone';


function MyMeetings(props) {

    const calendarRef = useRef(null);

    const [loading, setLoading] = useState(false);

    const [showInfoModal, setShowInfoModal] = useState(false);

    const [selectedEvent, setSelectedEvent] = useState({extendedProps: {}});

    function getClosestStartTime() {
        // return the closest 15 minute interval
        var coeff = 1000 * 60 * 15;
        var date = new Date();  //or use any other date
        var rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
        return rounded;
    }

    function getClosestEndTime() {
        // return the closest 15 minute interval + 30 mins
        var coeff = 1000 * 60 * 15;
        var date = new Date();  //or use any other date
        var rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
        rounded.setMinutes(rounded.getMinutes() + 30);
        return rounded;
    }

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedStartTime, setSelectedStartTime] = useState(getClosestStartTime());
    const [durationHours, setDurationHours] = useState(0);
    const [durationMinutes, setDurationMinutes] = useState(30);
    const [meetingDescription, setMeetingDescription] = useState('');
    const [meetingTitle, setMeetingTitle] = useState('');

    const [showModal, setShowModal] = useState(false);

    const [events, setEvents] = useState([{}]);
    const [isDeleting, setIsDeleting] = useState(false);

    const [showUpcoming, setShowUpcoming] = useState(false);

    const [participantLimit, setParticipantLimit] = useState('100'); // default to '100'
    const [customParticipantLimit, setCustomParticipantLimit] = useState(''); // for custom input
    const [selectedRecurrence, setSelectedRecurrence] = useState({zoom_occurrence_id: ""});

    const handleCustomButtonClick = () => {
      setShowModal(true);
    };

    const closeModal = () => {
      setShowModal(false);
    };

    const closeInfoModal = () => {
        setShowInfoModal(false);
    };

    const presentInfoModal = () => {
        setShowInfoModal(true);
    };

    const setEvent = (event) => {
        setSelectedEvent(event);
        setSelectedDate(new Date(event.start));
        setSelectedStartTime(new Date(event.start));
        setDurationHours(event.extendedProps.duration / 60);
        setDurationMinutes(event.extendedProps.duration % 60);
        setMeetingDescription(event.extendedProps.meetingAgenda);
        setMeetingTitle(event.extendedProps.topic);
        if(event.extendedProps.recurrence) {
            setSelectedRecurrence(event.extendedProps.recurrence);
        } else {
            setSelectedRecurrence({zoom_occurrence_id: ""});
        }

    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    // Handle time picker change
    const handleStartTimeChange = (time) => {
        setSelectedStartTime(time);
    };

   const handleDurationHoursChange = (e) => {
        setDurationHours(e.target.value);
    };

    const handleDurationMinutesChange = (e) => {
        setDurationMinutes(e.target.value);
    };

    const handleDescriptionChange = (event) => {
        if(event.target.value.length > 30) {
            alert("Meeting description cannot be more than 30 characters");
            return;
        }
        setMeetingDescription(event.target.value);
    };

    const handleTitleChange = (event) => {
        setMeetingTitle(event.target.value);
    }

    const handleShowDeleteMeetingModal = (event) => {
        setEvent(event);
        setShowDeleteModal(true);
      };
      


    const deleteMeeting = (event) => {
        if(window.confirm("Are you sure you want to delete this meeting?")) {
            const url = `${baseUrl}/api/meetings/${selectedEvent.extendedProps.id}`;
            const headers = new Headers();

            // Add headers to the headers object
            headers.append('uid', props.profile.uid);
            headers.append('Content-Type', 'application/json');

            setIsDeleting(true);

            const requestOptions = {
                method: 'DELETE',
                headers: headers
            }
            fetch(url, requestOptions)
                .then(response => {
                    if(response.status === 500) {
                        console.log('hi hey');
                        alert('There was an error deleting the zoom meeting. Please try again later');
                        setIsDeleting(false);
                        closeModal();
                        setShowDeleteModal(false);
                    }
                    return response.json();
                }
                )
                .then(data => {
                    if(data.message) {
                        console.log('hi hey the message is');
                        console.log(data.message);
                        alert(data.message);
                        setIsDeleting(false);
                        closeModal();
                        refresh();
                        setShowDeleteModal(false);
                    }
                }
                )
                .catch(error => {
                    alert('There was an error deleting the zoom meeting. Please try again later');
                    console.log('hi hey the error is');
                    console.log(error);
                    setIsDeleting(false);
                    closeModal();
                    setShowDeleteModal(false);
                }
                )   
            }
    }

    const deleteMeetingOccurrence = (event) => {
        if(window.confirm("Are you sure you want to delete this meeting?")) {
            const url = `${baseUrl}/api/meetings/${selectedEvent.extendedProps.id}/${selectedRecurrence.zoom_occurrence_id}`;
            const headers = new Headers();

            // Add headers to the headers object
            headers.append('uid', props.profile.uid);
            headers.append('Content-Type', 'application/json');

            setIsDeleting(true);

            const requestOptions = {
                method: 'DELETE',
                headers: headers
            }
            fetch(url, requestOptions)
                .then(response => {
                    if(response.status == 500) {
                        alert('There was an error deleting the zoom meeting. Please try again later');
                        setIsDeleting(false);
                        closeModal();
                        setShowDeleteModal(false);

                    }
                    return response.json();
                }
                )
                .then(data => {
                    if(data.message) {
                        alert(data.message);
                        setIsDeleting(false);
                        closeModal();
                        refresh();
                        setShowDeleteModal(false);
                    }
                }
                )
                .catch(error => {
                    alert('There was an error deleting the zoom meeting. Please try again later');
                    setIsDeleting(false);
                    closeModal();
                    setShowDeleteModal(false);
                }
                )   
        }
        else {
            return;
        }
    }
  
    const currentZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentMonthDateTime = new Date().setDate(1);

    function createMeetingAttendees() {
        const inputEmails = emailInput.split(/[\s,;]+/);
        const newEmails = [];
        const foundInvalidEmails = [];
      
        console.log(inputEmails);
      
        for (const email of inputEmails) {
            if (isValidEmail(email) && !emails.includes(email)) {
                newEmails.push(email);
            } else if (email.trim() !== '') {
                foundInvalidEmails.push(email);
            }
        }
      
        return newEmails;
      }

    function createMeeting() {

        // call meeting attendees function
        const newEmails = createMeetingAttendees();


        const url = baseUrl + '/api/meetings/';
        const headers = new Headers();

        // Add headers to the headers object
        headers.append('Content-Type', 'application/json');
        headers.append('uid', props.profile.uid);
            
            // Create moment objects from Date objects
        var startDateTime = moment(selectedDate).tz(selectedTimeZone);
        
        startDateTime.set({
            hour: selectedStartTime.getHours(),
            minute: selectedStartTime.getMinutes()
        });

        console.log(startDateTime.format());
        
        // Convert to UTC
        const startDateTimeString = startDateTime.utc().format();
        
        console.log(startDateTimeString);
        
        var hrs = parseInt(durationHours);
        var mins = parseInt(durationMinutes);
        
        //endDateTime = sstart date time + duration
        const duration = (hrs * 60) + mins;
        
        if(duration > 300) {
            console.log(hrs);
            console.log(mins);
            console.log(duration);
            alert("Meeting duration cannot be more than 5 hours");
            return;
        }

        var endDateTimeMoment = moment(startDateTime).tz(selectedTimeZone);
        endDateTimeMoment.add(hrs, 'hours').add(mins, 'minutes');

        // end date time must be less than 3 months from start date time
        if (endDateTimeMoment.diff(startDateTime, 'months') > 3) {
            alert("Meeting duration cannot be more than 3 months");
            return;
        }


        // Convert to UTC
        const endDateTimeString = endDateTimeMoment.utc().format();

        const participantLimitInt = participantLimit === 'custom' ? parseInt(customParticipantLimit) : parseInt(participantLimit);
        
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                "startDateTime": startDateTimeString,
                "endDateTime": endDateTimeString,
                "agenda": meetingDescription,
                "topic" : meetingDescription,
                "participantsCount" : participantLimitInt,
                "duration": duration,
                "topic" : meetingTitle,
                "timezone" : selectedTimeZone,
                "recurrence": null,
                "registrants" : emails.length > 0 ? emails : newEmails
            })
          }

          if (recurrence !== null) {

            var endDateTimeMoment = moment(endDateTime).tz(selectedTimeZone);
            
            endDateTimeMoment.set({
                hour: selectedStartTime.getHours(),
                minute: selectedStartTime.getMinutes()
            });

            endDateTimeMoment.add(hrs, 'hours').add(mins, 'minutes');
        
            // Convert to UTC
            const endDateTimeString = endDateTimeMoment.utc().format();

            // convert weekly_days to string
            const weeklyDaysString = recurrence.weekly_days.join(',');
            const formatted_recurrence = {
                type: recurrence.type,
                repeatInterval: recurrence.repeatInterval,
                monthly_day: recurrence.monthly_day,
                monthly_week: recurrence.monthly_week,
                weekly_days: weeklyDaysString,
                monthly_week_day: recurrence.monthly_week_day,
                monthlyOption: monthlyOption == 'date'
            };

            requestOptions.body = JSON.stringify({
                "startDateTime": startDateTimeString,
                "endDateTime": endDateTimeString,
                "agenda": meetingDescription,
                "topic" : meetingDescription,
                "participantsCount" : participantLimitInt,
                "duration": duration,
                "topic" : meetingTitle,
                "recurrence": formatted_recurrence,
                "registrants" : emails.length > 0 ? emails : newEmails,
                "timezone" : selectedTimeZone
            });
          }

    
        setLoading(true);
    
        fetch(url, requestOptions)
            .then(response => {
                // check if the response is a 404
                if (response.status == 500) {
                    var errorString = 'There was an error scheduling the zoom meeting. Please try again later';
                    alert(errorString);
                }
                return response.json();
            }
            )
            .then(data => {
                // check if data has 'message' field
                console.log(data)
                if (data.message) {
                    alert(data.message);
                    setLoading(false);
                    closeModal();
                    return;
                }
                else {
                    alert("Meeting scheduled successfully!");
                    setLoading(false);
                    closeModal();
                    refresh();
                    return;
                }
            })
            
            .catch(error => {
                setLoading(false);
                closeModal();
            });
    }

    const getFullCalendarInstance = () => {
        if (calendarRef.current) {
          return calendarRef.current.getApi(); // Use the getApi method to get the FullCalendar instance
        }
        return null;
      };

    function refresh() {
        const calendarInstance = getFullCalendarInstance();
        if (calendarInstance) {
            calendarInstance.refetchEvents();
        }
    }

    const memoEvents = useMemo(() => {
        return function (info, successCallback, failureCallback){
            var startDateTime = info.start;
            var endDateTime = info.end;

            startDateTime.setMilliseconds(0);
            endDateTime.setMilliseconds(0);

            startDateTime = startDateTime.toISOString();
            endDateTime = endDateTime.toISOString();
            
            startDateTime = startDateTime.substring(0, startDateTime.length - 5) + 'Z';
            endDateTime = endDateTime.substring(0, endDateTime.length - 5) + 'Z';

            const url = baseUrl + `/api/meetings/`; // Use backticks for template literals
            const headers = new Headers();
        
            // Add headers to the headers object
            headers.append('X-API-KEY', ",PRGojtX9`c4e24219-fb95-;'.4ed8392hfds';");
            headers.append('uid', props.profile.uid);
        
            const requestOptions = {
                method: 'GET',
                headers: headers
            };
        
            
            fetch(url, requestOptions)
            .then(response => {
                // check if the response is a 404
                if (response.status === 404) {
                    // if it is, then throw an error
                    failureCallback();
                }
                return response.json();
            })
            .then(data => {
                // map to events object
                var meeting_events = data.map((meeting) => {
                    
                    return {
                        title: meeting.topic,
                        start: meeting.startDateTime,
                        end: meeting.endDateTime,
                        extendedProps: {
                            id: meeting.id,
                            startUrl: meeting.meetingStartUrl,
                            joinUrl: meeting.meetingJoinUrl,
                            meetingId: meeting.zoomMeetingId,
                            meetingPassword: meeting.meetingPassword,
                            title: meeting.topic,
                            meetingAgenda: meeting.meetingAgenda,
                            recurrence: meeting.recurrence,
                            zoomMeetingId: meeting.zoomMeetingId
                        }
                    }
                });
                successCallback(meeting_events);
            })
            .catch(error => {
                // Handle any errors that occurred during the fetch
                failureCallback(error);
            });
        }
    }, [events]);


    const handleParticipantLimitChange = (e) => {
        setParticipantLimit(e.target.value);
        if (e.target.value !== 'custom') {
            setCustomParticipantLimit(''); // Reset custom limit if not selected
        }
    };
    const handleCustomParticipantLimitChange = (e) => setCustomParticipantLimit(e.target.value);
    const [emails, setEmails] = useState([]);
    const [emailInput, setEmailInput] = useState('');
    const [invalidEmails, setInvalidEmails] = useState([]);
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const inputEmails = emailInput.split(/[\s,;]+/);
            const newEmails = [];
            const foundInvalidEmails = [];

            for (const email of inputEmails) {
                if (isValidEmail(email) && !emails.includes(email)) {
                    newEmails.push(email);
                } else if (email.trim() !== '') {
                    foundInvalidEmails.push(email);
                }
            }

            if (newEmails.length > 0) {
                setEmails([...emails, ...newEmails]);
            }
            setEmailInput(''); // Clear input field after processing

            // Update state with invalid emails for alert
            if (foundInvalidEmails.length > 0) {
                setInvalidEmails(foundInvalidEmails);
            } else {
                setInvalidEmails([]); // Clear any previous invalid emails if none found this time
            }
        }
    };

    const removeEmail = (index) => {
        setEmails(emails.filter((_, i) => i !== index));
    };

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const [selectedTimeZone, setSelectedTimeZone] = useState(moment.tz.guess());
    const timeZones = moment.tz.names(); 

    const [recurrence, setRecurrence] = useState(null)

    const [endDateTime, setEndDateTime] = useState(new Date());
    const [type, setType] = useState(1); // 1 - daily, 2 - weekly, 3 - monthly
    const [repeatInterval, setRepeatInterval] = useState(1);
    const [monthlyDay, setMonthlyDay] = useState(1);
    const [monthlyWeek, setMonthlyWeek] = useState(1);
    const [weeklyDays, setWeeklyDays] = useState([1]);
    const [monthlyWeekDay, setMonthlyWeekDay] = useState(1);
    const [monthlyOption, setMonthlyOption] = useState('date'); // 'date' or 'week'


    const handleTimeZoneChange = (e) => {
        setSelectedTimeZone(e.target.value);
    };

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const TimeZoneInput = () => {

        return (
                <Form.Group controlId="timeZoneSelect">
                    <Form.Label>Time Zone</Form.Label>
                    <Form.Control as="select" value={selectedTimeZone} onChange={handleTimeZoneChange}>
                        {timeZones.map((zone) => (
                            <option key={zone} value={zone}>
                                {zone}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
        );
    }
      
    return (
        <div id='calendarContainer'>
            <div id='welcomeContainer'>
                <h3>Welcome, {props.profile.displayName}!</h3>
                {/* <Button onClick={refresh} id = 'refreshButton'>↻</Button> */}
            </div>
            
            <br></br>
            
                <FullCalendar
                ref={calendarRef}
                plugins={[listPlugin, bootstrap5Plugin]}
                initialView='listMonth'
                themeSystem='bootstrap5'
                validRange={{ "start": currentMonthDateTime }}
                headerToolbar={{
                    start: 'listMonth listWeek prev next',
                    center: 'title',
                    end: 'Schedule',
                }}
                views={
                    {listMonth: { buttonText: 'Month' },
                    listWeek: { buttonText: 'Week' }}
                }
                customButtons={{
                    Schedule: {
                        text: 'Schedule Meeting',
                        click: function () {
                            handleCustomButtonClick();
                        }
                    }
                }}
                events={memoEvents}
                eventDidMount={
                    function(info) {

                        let start = info.event.start.toISOString();
                        let end = info.event.end.toISOString();
                        let current = new Date().toISOString();

                        // if(current < end) {
                            var button = document.createElement('button');
                            button.innerHTML = 'Delete Meeting';
                            button.className = 'btn btn-primary btn-sm';
                            button.classList.add('float-end');
                            button.style.marginLeft = '10px';
                            button.style.borderRadius = '5px';
                            button.style.backgroundColor = '#dc3545';
                            button.style.borderColor = '#dc3545';
                            button.addEventListener('click', function() {
                                handleShowDeleteMeetingModal(info.event);
                            }
                            );

                            info.el.querySelector('.fc-list-event-title').appendChild(button);
                        // }

                        if(start <= current && end >= current) {
                            var button = document.createElement('button');
                            button.innerHTML = 'Start';
                            button.className = 'btn btn-primary btn-sm';
                            button.classList.add('float-end');
                            button.style.marginLeft = '10px';
                            button.style.borderRadius = '5px';
                            button.addEventListener('click', function() {
                                startMeeting(info.event.extendedProps.zoomMeetingId);
                            }
                            );
    
                            info.el.querySelector('.fc-list-event-title').appendChild(button);
                        }

                        // if(end > current) {
                            var button = document.createElement('button');
                            button.innerHTML = 'Info';
                            button.className = 'btn btn-primary btn-sm';
                            button.style.marginLeft = '10px';
                            button.style.borderRadius = '5px';
                            button.classList.add('float-end');
                            button.addEventListener('click', function() {
                                setEvent(info.event);
                                presentInfoModal();
                            }
                            );

                            // add button to the extreme right
                            info.el.querySelector('.fc-list-event-title').appendChild(button);
                        // }

                       var recurrence = info.event.extendedProps.recurrence;
                          if(recurrence && recurrence.zoom_occurrence_id !== "") {
                             // label telling user that this is a recurring event, occurence number and total number of occurences
                                
                             var occurrenceLabel = document.createElement('span');
                             occurrenceLabel.textContent = 'Recurring';
                             occurrenceLabel.className = 'badge bg-primary';
                             occurrenceLabel.style.marginLeft = '10px';
                             occurrenceLabel.style.color = 'white';
                             occurrenceLabel.style.padding = '5px';
                             occurrenceLabel.style.fontSize = '12px';
                             occurrenceLabel.style.fontWeight = 'bold';
                                
                                info.el.querySelector('.fc-list-event-title').appendChild(occurrenceLabel);
                          }

                    }
                }
            />
            <InfoMeetingModal />
            <Modal show={showModal}>
                <Modal.Header closeButton onClick={closeModal}>
                <Modal.Title>Schedule Meeting</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form >
                    <Form.Group controlId="datePicker">
                    <Form.Label className='form-label'>Date</Form.Label>
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="yyyy-MM-dd"
                        className='form-date-picker'
                    />
                    </Form.Group>
                    <Form.Group controlId="startTimePicker">
                    <Form.Label className='form-label'>Start Time</Form.Label>
                    <DatePicker
                        selected={selectedStartTime}
                        onChange={handleStartTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Start Time"
                        dateFormat="h:mm aa"
                        className='form-date-picker'
                    />
                    </Form.Group>
                    
                    <Form.Group controlId="duration">
                    <Form.Label className='form-label'>Duration</Form.Label>
                    <div>
                        <div className="duration-input-block">
                            <input
                                type="number"
                                min="0"
                                pattern="\d*"
                                step="1"
                                value={durationHours}
                                onChange={handleDurationHoursChange}
                                className='form-duration-input'
                            />
                            <span>hours</span>
                        </div>
                        <div className="duration-input-block">
                            <input
                                type="number"
                                min="0"
                                pattern="\d*"
                                step="15"
                                value={durationMinutes}
                                onChange={handleDurationMinutesChange}
                                className='form-duration-input'
                            />
                            <span>minutes</span>
                        </div>
                    </div>
                </Form.Group>
                    <Form.Group controlId="meetingTitle">
                    <Form.Label>Meeting Title</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter meeting title"
                        value={meetingTitle}
                        onChange={handleTitleChange}
                        className='form-text-box'
                    />
                    </Form.Group>
                    <Form.Group controlId="meetingDescription">
                    <Form.Label>Meeting Description</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter meeting description"
                        value={meetingDescription}
                        onChange={handleDescriptionChange}
                        className='form-text-box'
                    />
                    </Form.Group>
                    {/* Participant Limit Radio Buttons */}
                <fieldset>
                    <Form.Group>
                        <Form.Label>Participant Limit</Form.Label>
                        {['100', '300', '1000'].map((limit) => (
                            <Form.Check
                                key={limit}
                                type="radio"
                                label={limit}
                                name="participantLimitOptions"
                                id={`participantLimit${limit}`}
                                value={limit}
                                checked={participantLimit === limit}
                                onChange={handleParticipantLimitChange}
                            />
                        ))}
                    </Form.Group>
                </fieldset>
                <TimeZoneInput />
                {invalidEmails.length > 0 && (
                    <Alert variant="danger" onClose={() => setInvalidEmails([])} dismissible>
                        The following email(s) were invalid and have not been added: {invalidEmails.join(', ')}.
                    </Alert>
                )}
                <Form.Group controlId="emailTagsInput">
                    <Form.Label>Add Attendees</Form.Label>
                    <div style={{ padding: '5px', border: '1px solid #ced4da', borderRadius: '0.25rem', minHeight: '42px', display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                        {emails.map((email, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', background: '#007bff', color: 'white', padding: '2px 6px', borderRadius: '10px' }}>
                                {email}
                                <Button variant="light" size="sm" onClick={() => removeEmail(index)} style={{ marginLeft: '5px', lineHeight: '0.5' }}>x</Button>
                            </div>
                        ))}
                        <Form.Control
                            type="text"
                            value={emailInput}
                            onChange={(e) => setEmailInput(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="Enter emails separated by comma, space, or semicolon"
                            style={{ flexGrow: '1', border: 'none' }}
                        />
                    </div>
                </Form.Group>
                <RecurrenceInput
                recurrence={recurrence}
                setRecurrence={setRecurrence}
                endDateTime={endDateTime}
                setEndDateTime={setEndDateTime}
                type={type}
                setType={setType}
                repeatInterval={repeatInterval}
                setRepeatInterval={setRepeatInterval}
                monthlyDay={monthlyDay}
                setMonthlyDay={setMonthlyDay}
                monthlyWeek={monthlyWeek}
                setMonthlyWeek={setMonthlyWeek}
                weeklyDays={weeklyDays}
                setWeeklyDays={setWeeklyDays}
                monthlyWeekDay={monthlyWeekDay}
                setMonthlyWeekDay={setMonthlyWeekDay}
                monthlyOption={monthlyOption}
                setMonthlyOption={setMonthlyOption}
                />
                </Form>
            </Modal.Body>
            <Modal.Footer>
              
                <Button variant="danger" onClick={closeModal}>
                Cancel
                </Button>
                <Button variant="dark" onClick={createMeeting}>
                Schedule
                </Button>
                <Spinner id='spinner' animation="border" role="status" hidden={!loading}> </Spinner>
            </Modal.Footer>
            </Modal>
            <Modal show={showDeleteModal}>
                <Modal.Header closeButton onClick={() => setShowDeleteModal(false)}>
                    <Modal.Title>Delete Meeting</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this meeting?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteMeetingOccurrence}>
                        Delete
                    </Button>
                    {selectedRecurrence.zoom_occurrence_id !== "" && (
                        <Button variant="primary" onClick={deleteMeeting}>
                            Delete All Occurrences
                        </Button>
                    )
                    }
                    <Spinner animation="border" role="status" hidden={!isDeleting}> </Spinner>
                </Modal.Footer>
            </Modal>
        </div>
    );

    function refreshMeeting() {
        const meetingId = selectedEvent.extendedProps.zoomMeetingId;
        var url = `${baseUrl}/api/meetings/${meetingId}`;
        const headers = new Headers();
        
        if(selectedRecurrence && selectedRecurrence.zoom_occurrence_id !== "") {
            const occId = selectedRecurrence.zoom_occurrence_id;
            url = `${baseUrl}/api/meetings/${meetingId}/${occId}`;
        }
        

        // Add headers to the headers object
        headers.append('uid', props.profile.uid);
        headers.append('Content-Type', 'application/json');

        const requestOptions = {
            method: 'PUT',
            headers: headers
        }
        fetch(url, requestOptions)
            .then(response => {
                // check if the response is a 404
                if (response.status === 404) {
                    // if it is, then throw an error
                    alert('There was an error refreshing the zoom meeting. Please try again later');
                    return;
                }
                return response.json();
            }
            )
            .then(data => {
                // Handle the JSON data here
                if(data == null) {
                    return;
                }
                refresh();

                alert('Meeting refreshed successfully');

            }
            )
            .catch(error => {
                alert('There was an error refreshing the zoom meeting. Please try again later');
            }
            )
    }

    function startMeeting(meetingId) {
        const url = `${baseUrl}/api/meetings/${meetingId}`;
        const headers = new Headers();
        
        

        // Add headers to the headers object
        headers.append('uid', props.profile.uid);
        headers.append('Content-Type', 'application/json');

        const requestOptions = {
            method: 'PUT',
            headers: headers
        }
        fetch(url, requestOptions)
            .then(response => {
                // check if the response is a 404
                if (response.status === 404) {
                    // if it is, then throw an error
                    alert('There was an error refreshing the zoom meeting. Please try again later');
                    return null;
                }
                return response.json();
            }
            )
            .then(data => {
                // Handle the JSON data here
                if(data == null) {
                    return;
                }
                console.log(data);
                window.open(data.meetingStartUrl);
            }
            )
            .catch(error => {
                alert('There was an error refreshing the zoom meeting. Please try again later');
            }
            )
    }

    function RecurrenceInput(props) {

        const { recurrence, 
            setRecurrence, 
            endDateTime, 
            setEndDateTime,
            type,
            setType,
            repeatInterval,
            setRepeatInterval,
            monthlyDay,
            setMonthlyDay,
            monthlyWeek,
            setMonthlyWeek,
            weeklyDays,
            setWeeklyDays,
            monthlyWeekDay,
            setMonthlyWeekDay,
            monthlyOption,
            setMonthlyOption
        } = props;
      
        const handleTypeChange = (e) => {
          setType(parseInt(e.target.value));
          setRecurrence((prev) => ({
            ...prev,
            type: parseInt(e.target.value),
          }));
        };
      
        const handleRepeatIntervalChange = (e) => {
          setRepeatInterval(parseInt(e.target.value));
          setRecurrence((prev) => ({
            ...prev,
            repeatInterval: parseInt(e.target.value),
          }));
        };
      
        const handleMonthlyDayChange = (e) => {
          setMonthlyDay(parseInt(e.target.value));
          setRecurrence((prev) => ({
            ...prev,
            monthly_day: parseInt(e.target.value),
          }));
        };
      
        const handleMonthlyWeekChange = (e) => {
          setMonthlyWeek(parseInt(e.target.value));
          setRecurrence((prev) => ({
            ...prev,
            monthly_week: parseInt(e.target.value),
          }));
        };
      
        const handleWeeklyDaysChange = (e) => {
          const value = parseInt(e.target.value);
          setWeeklyDays((prev) =>
            prev.includes(value)
              ? prev.filter((day) => day !== value)
              : [...prev, value]
          );
          setRecurrence((prev) => ({
            ...prev,
            weekly_days: weeklyDays.includes(value)
              ? prev.weekly_days.filter((day) => day !== value)
              : [...prev.weekly_days, value],
          }));
        };
      
        const handleMonthlyWeekDayChange = (e) => {
          setMonthlyWeekDay(parseInt(e.target.value));
          setRecurrence((prev) => ({
            ...prev,
            monthly_week_day: parseInt(e.target.value),
          }));
        };
      
        const handleMonthlyOptionChange = (e) => {
          setMonthlyOption(e.target.value);
        };
      
        const getWeekDayName = (dayNumber) => {
          const daysOfWeek = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ];
          return daysOfWeek[dayNumber - 1];
        };
      
        const getOrdinalSuffix = (number) => {
          const lastDigit = number % 10;
          const lastTwoDigits = number % 100;
      
          if (lastDigit === 1 && lastTwoDigits !== 11) {
            return `${number}st`;
          } else if (lastDigit === 2 && lastTwoDigits !== 12) {
            return `${number}nd`;
          } else if (lastDigit === 3 && lastTwoDigits !== 13) {
            return `${number}rd`;
          } else {
            return `${number}th`;
          }
        };
      
        const getRecurrenceDescription = () => {
          if (recurrence === null) {
            return 'The meeting does not recur.';
          }
      
          let description = '';
      
          if (type === 1) {
            description = `Repeat every ${repeatInterval} day${repeatInterval > 1 ? 's' : ''}`;
          } else if (type === 2) {
            const selectedWeekDays = weeklyDays.map((day) => getWeekDayName(day));
            description = `Repeat every ${repeatInterval} week${repeatInterval > 1 ? 's' : ''} on ${selectedWeekDays.join(', ')}`;
          } else if (type === 3) {
            description = `Repeat every ${repeatInterval} month${repeatInterval > 1 ? 's' : ''}`;
      
            if (monthlyOption === 'date') {
                description += ` on the ${getOrdinalSuffix(monthlyDay)} day of the month`;
            } else {
              const ordinalSuffix = monthlyWeek === -1 ? 'last' : getOrdinalSuffix(monthlyWeek);
              description += ` on the ${ordinalSuffix} ${getWeekDayName(monthlyWeekDay)} of the month`;
            }
          }
      
          return description;
        };

        const handleRecurrenceChange = (e) => {
            if (e.target.value === 'none') {
              setRecurrence(null);
            } else {
              setRecurrence({
                  type: 1,
                  repeatInterval: 1,
                  monthly_day: 1,
                  monthly_week: 1,
                  weekly_days: [1],
                  monthly_week_day: 1
              })
            }
          };

          const handleSetEndDateTime = (date) => {
            // end date time can be at max 3 months from the start date time
            const maxEndDate = new Date(endDateTime);
            maxEndDate.setMonth(maxEndDate.getMonth() + 3);
            if (date > maxEndDate) {
                alert('End date time cannot be more than 3 months from the start date time');
                return;
            }
            setEndDateTime(date);
          }
      
        return (
          <div>
            <Form.Group controlId="recurrenceInput">             
            <Form.Label style={{ fontWeight: 'bold' }}>
            {getRecurrenceDescription()}
            </Form.Label>
              <Form.Check
                type="radio"
                label="None"
                name="recurrenceOptions"
                id="recurrenceNone"
                value="none"
                checked={recurrence === null}
                onChange={handleRecurrenceChange}
              />
              <Form.Check
                type="radio"
                label="Recurring"
                name="recurrenceOptions"
                id="recurrenceRecurring"
                value="recurring"
                checked={recurrence !== null}
                onChange={handleRecurrenceChange}
              />
            </Form.Group>
      
            {recurrence !== null && (
              <div>
                <Form.Group controlId="datePicker">
                    <Form.Label className='form-label'>End Date</Form.Label>
                    <DatePicker
                        selected={endDateTime}
                        onChange={handleSetEndDateTime}
                        dateFormat="yyyy-MM-dd"
                        className='form-date-picker'
                    />
                </Form.Group>
                
                <Form.Group controlId="recurrenceTypeInput">
                  <Form.Label>Recurrence Type</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Daily"
                    name="recurrenceTypeOptions"
                    id="recurrenceTypeDaily"
                    value={1}
                    checked={type === 1}
                    onChange={handleTypeChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Weekly"
                    name="recurrenceTypeOptions"
                    id="recurrenceTypeWeekly"
                    value={2}
                    checked={type === 2}
                    onChange={handleTypeChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Monthly"
                    name="recurrenceTypeOptions"
                    id="recurrenceTypeMonthly"
                    value={3}
                    checked={type === 3}
                    onChange={handleTypeChange}
                  />
                </Form.Group>
      
                <Form.Group controlId="repeatIntervalInput">
                  <Form.Label>Repeat Every</Form.Label>
                  <Form.Control
                    type="number"
                    min="1"
                    value={repeatInterval}
                    onChange={handleRepeatIntervalChange}
                  />
                </Form.Group>
      
                {type === 3 && (
                  <div>
                    <Form.Group controlId="monthlyOptionInput">
                      <Form.Label>Monthly Option</Form.Label>
                      <Form.Check
                        type="radio"
                        label="By Date"
                        name="monthlyOptionOptions"
                        id="monthlyOptionDate"
                        value="date"
                        checked={monthlyOption === 'date'}
                        onChange={handleMonthlyOptionChange}
                      />
                      <Form.Check
                        type="radio"
                        label="By Week and Day"
                        name="monthlyOptionOptions"
                        id="monthlyOptionWeekDay"
                        value="week"
                        checked={monthlyOption === 'week'}
                        onChange={handleMonthlyOptionChange}
                      />
                    </Form.Group>
      
                    {monthlyOption === 'date' && (
                      <Form.Group controlId="monthlyDayInput">
                      <Form.Label>Monthly Day</Form.Label>
                      <Form.Control as="select" value={monthlyDay} onChange={handleMonthlyDayChange}>
                        {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                          <option key={day} value={day}>{day}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    )}
      
                    {monthlyOption === 'week' && (
                      <div>
                        <Form.Group controlId="monthlyWeekInput">
                        <Form.Label>Monthly Week</Form.Label>
                        <Form.Control as="select" value={monthlyWeek} onChange={handleMonthlyWeekChange}>
                            <option value="-1">Last Week</option>
                            <option value="1">First Week</option>
                            <option value="2">Second Week</option>
                            <option value="3">Third Week</option>
                            <option value="4">Fourth Week</option>
                        </Form.Control>
                        </Form.Group>
      
                        <Form.Group controlId="monthlyWeekDayInput">
                        <Form.Label>Monthly Week Day</Form.Label>
                        <Form.Control as="select" value={monthlyWeekDay} onChange={handleMonthlyWeekDayChange}>
                            <option value="1">Sunday</option>
                            <option value="2">Monday</option>
                            <option value="3">Tuesday</option>
                            <option value="4">Wednesday</option>
                            <option value="5">Thursday</option>
                            <option value="6">Friday</option>
                            <option value="7">Saturday</option>
                        </Form.Control>
                        </Form.Group>
                      </div>
                    )}
                  </div>
                )}
      
                {type === 2 && (
                  <Form.Group controlId="weeklyDaysInput">
                    <Form.Label>Weekly Days</Form.Label>
                    {[1, 2, 3, 4, 5, 6, 7].map((day) => (
                      <Form.Check
                        key={day}
                        type="checkbox"
                        label={getWeekDayName(day)}
                        value={day}
                        checked={weeklyDays.includes(day)}
                        onChange={handleWeeklyDaysChange}
                      />
                    ))}
                  </Form.Group>
                )}
              </div>
            )}
          </div>
        );
      }
      

    function InfoMeetingModal() {

        const copyToClipboard = async (text) => {
            try {
                await navigator.clipboard.writeText(text);
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        };
    
        const copyMeetingInfo = () => {
            const meetingInfo = `Meeting ID: ${selectedEvent.extendedProps.meetingId}\n` +
                `Meeting Password: ${selectedEvent.extendedProps.meetingPassword}\n` +
                `Start Time: ${new Date(selectedEvent.start).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} ${currentZone}\n` +
                `End Time: ${new Date(selectedEvent.end).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} ${currentZone}\n` +
                `Meeting Title: ${selectedEvent.extendedProps.title}\n` +
                `Meeting Description: ${selectedEvent.extendedProps.meetingAgenda}\n` +
                `Join Url: ${selectedEvent.extendedProps.joinUrl}\n` +
                `Start Url: ${selectedEvent.extendedProps.startUrl}`;
    
            copyToClipboard(meetingInfo);
        };
    
        return (
            <Modal show={showInfoModal}>
                <Modal.Header closeButton onClick={closeInfoModal} >
                    <Modal.Title>
                        Meeting Details
                    </Modal.Title>
                    <span style={{ marginLeft: '1.5rem' }}> {/* Adjust the margin value as needed */}
                        <Button variant="outline-primary" size="sm" onClick={copyMeetingInfo}>
                            Copy Meeting Info
                        </Button>
                    </span>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {selectedRecurrence.zoom_occurrence_id !== "" && (
                            <div>
                                <span className="badge bg-primary">Recurring</span>
                                <p>Occurrence: {selectedEvent.extendedProps.recurrence.currentOccurence} of {selectedEvent.extendedProps.recurrence.totalOccurences}</p>
                            </div>
                        )}
                        <p>Meeting ID: {selectedEvent.extendedProps.meetingId}</p>
                        <p>Meeting Password: {selectedEvent.extendedProps.meetingPassword}</p>
                        <p>Start Time: {
                            // format time to current zone in hh:mm am/pm format
                            new Date(selectedEvent.start).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                        } {currentZone}
                        </p>
                        <p>End Time: {
                            // format time to current zone in hh:mm am/pm format
                            new Date(selectedEvent.end).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                        } {currentZone}</p>
                        <p>Meeting Title: {selectedEvent.extendedProps.title}</p>
                        <p>Meeting Description: {selectedEvent.extendedProps.meetingAgenda}</p>
                        <div className="url-container">
                            <div>Join Url: {selectedEvent.extendedProps.joinUrl}</div>
                            <Button variant="outline-primary" size="sm" onClick={() => copyToClipboard(selectedEvent.extendedProps.joinUrl)}>
                                Copy
                            </Button>
                        </div>
    
                        <div className="url-container">
                            <div>Start Url: {selectedEvent.extendedProps.startUrl}</div>
                            <Button variant="outline-primary" size="sm" onClick={() => copyToClipboard(selectedEvent.extendedProps.startUrl)}>
                                Copy
                            </Button>
                        </div>
                    </div>
    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeInfoModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={refreshMeeting}>
                        Refresh Meeting Urls
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
    
}

export default MyMeetings;
