import React, { useState, useRef, useMemo } from 'react';
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid'
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './AvailableSlots.css';
import {Modal, Button, Form, Alert} from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Spinner from 'react-bootstrap/Spinner';
import baseUrl from '../api'
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment-timezone';


function AvailableSlotsView(props) {

    const currentDateTime = new Date();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [showModal, setShowModal] = useState(false);
    const calendarRef = useRef(null);

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedStartTime, setSelectedStartTime] = useState(getClosestStartTime());
    const [durationHours, setDurationHours] = useState(0);
    const [durationMinutes, setDurationMinutes] = useState(30);
    const [meetingDescription, setMeetingDescription] = useState('');
    const [meetingTitle, setMeetingTitle] = useState('');
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);

    const [participantLimit, setParticipantLimit] = useState('100'); // default to '100'
    const [customParticipantLimit, setCustomParticipantLimit] = useState(''); // for custom input
    const currentZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentMonthDateTime = new Date().setDate(1);


    function getClosestStartTime() {
        // return the closest 15 minute interval
        var coeff = 1000 * 60 * 15;
        var date = new Date();  //or use any other date
        var rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
        return rounded;
    }

    function getClosestEndTime() {
        // return the closest 15 minute interval + 30 mins
        var coeff = 1000 * 60 * 15;
        var date = new Date();  //or use any other date
        var rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
        rounded.setMinutes(rounded.getMinutes() + 30);
        return rounded;
    }


    const handleCustomButtonClick = () => {
      setShowModal(true);
    };

    const closeModal = () => {
      setShowModal(false);
    };

    const handleDateChange = (date) => {
      setSelectedDate(date);
    };

    // Handle time picker change
    const handleStartTimeChange = (time) => {
      setSelectedStartTime(time);
    };


    const handleTitleChange = (event) => {
        setMeetingTitle(event.target.value);
    }

  // Handle text box change
  const handleDescriptionChange = (event) => {
    if(event.target.value.length > 30) {
        alert("Meeting description cannot be more than 30 characters");
        return;
    }
    setMeetingDescription(event.target.value);
  };

  const handleDurationHoursChange = (e) => {
    setDurationHours(e.target.value);
};

const handleDurationMinutesChange = (e) => {
    setDurationMinutes(e.target.value);
};

function createMeetingAttendees() {
  const inputEmails = emailInput.split(/[\s,;]+/);
  const newEmails = [];
  const foundInvalidEmails = [];

  console.log(inputEmails);

  for (const email of inputEmails) {
      if (isValidEmail(email) && !emails.includes(email)) {
          newEmails.push(email);
      } else if (email.trim() !== '') {
          foundInvalidEmails.push(email);
      }
  }

  return newEmails;
}

  function createMeeting() {

    

    // call meeting attendees function
    const newEmails = createMeetingAttendees();

    const url = baseUrl + '/api/meetings/';
    const headers = new Headers();

    // Add headers to the headers object
    headers.append('Content-Type', 'application/json');
    headers.append('uid', props.profile.uid);
        
        // Create moment objects from Date objects
    var startDateTime = moment(selectedDate).tz(selectedTimeZone);
    
    startDateTime.set({
        hour: selectedStartTime.getHours(),
        minute: selectedStartTime.getMinutes()
    });

    // Convert to UTC
    const startDateTimeString = startDateTime.utc().format();
  
    var hrs = parseInt(durationHours);
    var mins = parseInt(durationMinutes);
    
    //endDateTime = sstart date time + duration
    const duration = (hrs * 60) + mins;

    if(duration > 300) {
        console.log(duration);
        alert("Meeting duration cannot be more than 5 hours");
        return;
    }

    var endDateTimeMoment = moment(startDateTime).tz(selectedTimeZone);
    endDateTimeMoment.add(durationHours, 'hours').add(durationMinutes, 'minutes');    

    // Convert to UTC
    const endDateTimeString = endDateTimeMoment.utc().format();

    // end date time must be less than 3 months from start date time
    if (endDateTimeMoment.diff(startDateTime, 'months') > 3) {
        alert("Meeting duration cannot be more than 3 months");
        return;
    }

    const participantLimitInt = participantLimit === 'custom' ? parseInt(customParticipantLimit) : parseInt(participantLimit);
    
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            "startDateTime": startDateTimeString,
            "endDateTime": endDateTimeString,
            "agenda": meetingDescription,
            "topic" : meetingDescription,
            "participantsCount" : participantLimitInt,
            "duration": duration,
            "topic" : meetingTitle,
            "timezone" : selectedTimeZone,
            "recurrence": null,
            "registrants" : emails.length == 0 ? newEmails : emails
        })
      }

      if (recurrence !== null) {

        var endDateTimeMoment = moment(endDateTime).tz(selectedTimeZone);
        
        endDateTimeMoment.set({
            hour: selectedStartTime.getHours(),
            minute: selectedStartTime.getMinutes()
        });

        endDateTimeMoment.add(durationHours, 'hours').add(durationMinutes, 'minutes');
    
        // Convert to UTC
        const endDateTimeString = endDateTimeMoment.utc().format();

        // convert weekly_days to string
        const weeklyDaysString = recurrence.weekly_days.join(',');
        const formatted_recurrence = {
            type: recurrence.type,
            repeatInterval: recurrence.repeatInterval,
            monthly_day: recurrence.monthly_day,
            monthly_week: recurrence.monthly_week,
            weekly_days: weeklyDaysString,
            monthly_week_day: recurrence.monthly_week_day,
            monthlyOption: monthlyOption == 'date'
        };

        requestOptions.body = JSON.stringify({
            "startDateTime": startDateTimeString,
            "endDateTime": endDateTimeString,
            "agenda": meetingDescription,
            "topic" : meetingDescription,
            "participantsCount" : participantLimitInt,
            "duration": duration,
            "topic" : meetingTitle,
            "recurrence": formatted_recurrence,
            "registrants" : emails.length == 0 ? newEmails : emails,
            "timezone" : selectedTimeZone
        });
      }


    setLoading(true);

    fetch(url, requestOptions)
        .then(response => {
            // check if the response is a 404
            if (response.status == 500) {
                var errorString = 'There was an error scheduling the zoom meeting. Please try again later';
                alert(errorString);
            }
            return response.json();
        }
        )
        .then(data => {
            // check if data has 'message' field
            console.log(data)
            if (data.message) {
                alert(data.message);
                setLoading(false);
                closeModal();
                return;
            }
            else {
                alert("Meeting scheduled successfully!");
                setLoading(false);
                closeModal();
                refresh();
                return;
            }
        })
        
        .catch(error => {
            setLoading(false);
            closeModal();
        });
}
const [emails, setEmails] = useState([]);
    const [emailInput, setEmailInput] = useState('');
    const [invalidEmails, setInvalidEmails] = useState([]);
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const inputEmails = emailInput.split(/[\s,;]+/);
            const newEmails = [];
            const foundInvalidEmails = [];

            for (const email of inputEmails) {
                if (isValidEmail(email) && !emails.includes(email)) {
                    newEmails.push(email);
                } else if (email.trim() !== '') {
                    foundInvalidEmails.push(email);
                }
            }

            if (newEmails.length > 0) {
                setEmails([...emails, ...newEmails]);
            }
            setEmailInput(''); // Clear input field after processing

            // Update state with invalid emails for alert
            if (foundInvalidEmails.length > 0) {
                setInvalidEmails(foundInvalidEmails);
            } else {
                setInvalidEmails([]); // Clear any previous invalid emails if none found this time
            }
        }
    };

    const removeEmail = (index) => {
        setEmails(emails.filter((_, i) => i !== index));
    };

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const [selectedTimeZone, setSelectedTimeZone] = useState(moment.tz.guess());
    const timeZones = moment.tz.names(); 

    const [recurrence, setRecurrence] = useState(null);

    const [endDateTime, setEndDateTime] = useState(new Date());
    const [type, setType] = useState(1); // 1 - daily, 2 - weekly, 3 - monthly
    const [repeatInterval, setRepeatInterval] = useState(1);
    const [monthlyDay, setMonthlyDay] = useState(1);
    const [monthlyWeek, setMonthlyWeek] = useState(1);
    const [weeklyDays, setWeeklyDays] = useState([1]);
    const [monthlyWeekDay, setMonthlyWeekDay] = useState(1);
    const [monthlyOption, setMonthlyOption] = useState('date'); // 'date' or 'week'


    const handleTimeZoneChange = (e) => {
        setSelectedTimeZone(e.target.value);
    };

    const TimeZoneInput = () => {

        return (
                <Form.Group controlId="timeZoneSelect">
                    <Form.Label>Time Zone</Form.Label>
                    <Form.Control as="select" value={selectedTimeZone} onChange={handleTimeZoneChange}>
                        {timeZones.map((zone) => (
                            <option key={zone} value={zone}>
                                {zone}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
        );
    }

const eventDisplay = (view) => {
    if (view.type === "dayGridMonth") {
      return "none"; // Do not display events in the month view
    }
    return "block"; // Display events in other views
  };

  const getFullCalendarInstance = () => {
    if (calendarRef.current) {
      return calendarRef.current.getApi(); // Use the getApi method to get the FullCalendar instance
    }
    return null;
  };

function refresh() {
    const calendarInstance = getFullCalendarInstance();
    if (calendarInstance) {
        calendarInstance.refetchEvents();
    }
}
const memoEvents = useMemo(() => {
  return function(info, successCallback, failureCallback) {
    var startDateTime = info.start;
    var endDateTime = info.end;

    startDateTime.setMilliseconds(0);
    startDateTime = startDateTime.toISOString();

    endDateTime.setMilliseconds(0);
    endDateTime = endDateTime.toISOString();
    endDateTime = endDateTime.substring(0, endDateTime.length - 5) + 'Z';
    startDateTime = startDateTime.substring(0, startDateTime.length - 5) + 'Z';

    const url = `${baseUrl}/api/meetings/unavailable/${startDateTime}/${endDateTime}`;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('uid', props.profile.uid);

    const requestOptions = {
      method: 'GET',
      headers: headers,
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (response.status === 404) {
          failureCallback();
          return Promise.reject('404 Not Found');
        }
        return response.json();
      })
      .then((data) => {
        const unavailableSlots = data[0];
        const mySlots = data[1];

        var blockedEvents = unavailableSlots.map((event) => ({
          start: event.startDateTime,
          end: event.endDateTime,
          backgroundColor: 'red',
          textColor: 'white',
        }));

        var myEvents = mySlots.map((event) => ({
          start: event.startDateTime,
          end: event.endDateTime,
          backgroundColor: 'blue',
          title: event.topic,
          textColor: 'white',
        }));

        console.log(props.profile.role);

        if (props.profile.role === 'ADMIN') {
          const slotsUrl = `${baseUrl}/api/meetings/slots/${startDateTime}/${endDateTime}`;
          return fetch(slotsUrl, requestOptions)
            .then((response) => response.json())
            .then((slotsData) => {
              const slots = slotsData.map((slot) => ({
                start: slot.startDateTime,
                end: slot.endDateTime,
                title: `${slot.topic}\n${slot.zoomUserDto.email}`,
                backgroundColor: 'green',
                textColor: 'white',
              }));

              var allEvents = [...blockedEvents, ...myEvents, ...slots];
              successCallback(allEvents);
            })
            .catch((error) => {
              failureCallback(error);
            });
        } else {
          const allEvents = [...blockedEvents, ...myEvents];
          successCallback(allEvents);
          return Promise.resolve();
        }
      })
      .catch((error) => {
        failureCallback(error);
      });
  };
}, [events]);


const handleParticipantLimitChange = (e) => {
    setParticipantLimit(e.target.value);
    if (e.target.value !== 'custom') {
        setCustomParticipantLimit(''); // Reset custom limit if not selected
    }
};

function didTapDate(info) {

    // set the selected date and time and open the modal
    setSelectedDate(info.date);
    setSelectedStartTime(info.date);
    
    // set the end time to 30 minutes after the start time
    var endTime = new Date(info.date);
    endTime.setMinutes(endTime.getMinutes() + 30);

    setShowModal(true);

}

const handleCustomParticipantLimitChange = (e) => setCustomParticipantLimit(e.target.value);


    return (
      <div id='calendarContainer'>
            <h3>Available Slots</h3>
            <h6>Your meetings are shown in blue. Unavailable slots are shown in red.</h6>
            {
                props.profile.role === 'ADMIN' && (
                    <h6>Slots booked by other users but are still free with some account are shown in green</h6>
                )
            }
            <br></br>
            
              <FullCalendar
                nowIndicator={false}
                slotEventOverlap={true}
                allDaySlot={false}
                slotDuration={'00:15:00'}
                ref={calendarRef}
                navLinks={true}
                validRange={{ "start": currentDateTime }}
                themeSystem='bootstrap5'
                titleFormat={{ year: 'numeric', month: 'short', day: 'numeric' }}
                headerToolbar={{
                    left: "dayGridMonth timeGridWeek timeGridDay prev next",
                    center: "title",
                    right: "Schedule"
                }}
                initialView='timeGridWeek'
                plugins={[daygridPlugin, timeGridPlugin, bootstrap5Plugin, interactionPlugin]}
                views={["dayGridMonth", "timeGridWeek", "timeGridDay"]}
                customButtons={{
                    Schedule: {
                        text: 'Schedule Meeting',
                        click: function () {
                            handleCustomButtonClick();
                        }
                    }
                }}
                events={memoEvents}
                // eventDisplay='background'
                // eventBackgroundColor='#FF0000'
                eventTextColor='#FFFFFF'
                dateClick={didTapDate}
                eventMaxStack={true}
                dayMaxEvents={true}
                eventMinHeight={50}
                eventMouseEnter={function(info) {
                    info.el.style.cursor = 'pointer';

                    // show the event title on hover over the event
                    var tooltip = document.createElement('div');
                    tooltip.style.position = 'absolute';
                    tooltip.style.zIndex = 1000;
                    tooltip.style.backgroundColor = 'white';
                    tooltip.style.border = '1px solid black';
                    tooltip.style.padding = '5px';
                    tooltip.style.borderRadius = '5px';
                    tooltip.style.top = info.jsEvent.clientY + 'px';
                    tooltip.style.left = info.jsEvent.clientX + 'px';

                    // subtract page scroll from tooltip position
                    tooltip.style.top = info.jsEvent.clientY + window.scrollY + 'px';
                    tooltip.style.left = info.jsEvent.clientX + window.scrollX + 'px';


                    var eventTitle = document.createElement('div');
                    eventTitle.textContent = info.event.title;
                    tooltip.appendChild(eventTitle);

                    document.body.appendChild(tooltip);
                }
                }
                eventMouseLeave={
                    function(info) {
                        document.body.querySelectorAll('div').forEach(function(element) {
                            if (element.style.zIndex == 1000) {
                                element.remove();
                            }
                        });
                    } 
                }
            />
            
            <Modal show={showModal}>
                <Modal.Header closeButton onClick={closeModal}>
                <Modal.Title>Schedule Meeting</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form >
                    <Form.Group controlId="datePicker">
                    <Form.Label className='form-label'>Date</Form.Label>
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="yyyy-MM-dd"
                        className='form-date-picker'
                    />
                    </Form.Group>
                    <Form.Group controlId="startTimePicker">
                    <Form.Label className='form-label'>Start Time</Form.Label>
                    <DatePicker
                        selected={selectedStartTime}
                        onChange={handleStartTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Start Time"
                        dateFormat="h:mm aa"
                        className='form-date-picker'
                    />
                    </Form.Group>

                    <div>
                        <div className="duration-input-block">
                            <input
                                type="number"
                                min="0"
                                step="1"
                                value={durationHours}
                                onChange={handleDurationHoursChange}
                                className='form-duration-input'
                            />
                            <span>hours</span>
                        </div>
                        <div className="duration-input-block">
                            <input
                                type="number"
                                min="0"
                                step="15"
                                value={durationMinutes}
                                onChange={handleDurationMinutesChange}
                                className='form-duration-input'
                            />
                            <span>minutes</span>
                        </div>
                    </div>
                   
                    <Form.Group controlId="meetingTitle">
                    <Form.Label>Meeting Title</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter meeting title"
                        value={meetingTitle}
                        onChange={handleTitleChange}
                        className='form-text-box'
                    />
                    </Form.Group>
                    <Form.Group controlId="meetingDescription">
                    <Form.Label>Meeting Description</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter meeting description"
                        value={meetingDescription}
                        onChange={handleDescriptionChange}
                        className='form-text-box'
                    />
                    </Form.Group>
                    {/* Participant Limit Radio Buttons */}
                <fieldset>
                    <Form.Group>
                        <Form.Label>Participant Limit</Form.Label>
                        {['100', '300', '1000'].map((limit) => (
                            <Form.Check
                                key={limit}
                                type="radio"
                                label={limit}
                                name="participantLimitOptions"
                                id={`participantLimit${limit}`}
                                value={limit}
                                checked={participantLimit === limit}
                                onChange={handleParticipantLimitChange}
                            />
                        ))}
                    </Form.Group>
                </fieldset>
                <TimeZoneInput />
                {invalidEmails.length > 0 && (
                    <Alert variant="danger" onClose={() => setInvalidEmails([])} dismissible>
                        The following email(s) were invalid and have not been added: {invalidEmails.join(', ')}.
                    </Alert>
                )}
                <Form.Group controlId="emailTagsInput">
                    <Form.Label>Add Attendees</Form.Label>
                    <div style={{ padding: '5px', border: '1px solid #ced4da', borderRadius: '0.25rem', minHeight: '42px', display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                        {emails.map((email, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', background: '#007bff', color: 'white', padding: '2px 6px', borderRadius: '10px' }}>
                                {email}
                                <Button variant="light" size="sm" onClick={() => removeEmail(index)} style={{ marginLeft: '5px', lineHeight: '0.5' }}>x</Button>
                            </div>
                        ))}
                        <Form.Control
                            type="text"
                            value={emailInput}
                            onChange={(e) => setEmailInput(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="Enter emails separated by comma, space, or semicolon"
                            style={{ flexGrow: '1', border: 'none' }}
                        />
                    </div>
                </Form.Group>
                <RecurrenceInput
                recurrence={recurrence}
                setRecurrence={setRecurrence}
                endDateTime={endDateTime}
                setEndDateTime={setEndDateTime}
                type={type}
                setType={setType}
                repeatInterval={repeatInterval}
                setRepeatInterval={setRepeatInterval}
                monthlyDay={monthlyDay}
                setMonthlyDay={setMonthlyDay}
                monthlyWeek={monthlyWeek}
                setMonthlyWeek={setMonthlyWeek}
                weeklyDays={weeklyDays}
                setWeeklyDays={setWeeklyDays}
                monthlyWeekDay={monthlyWeekDay}
                setMonthlyWeekDay={setMonthlyWeekDay}
                monthlyOption={monthlyOption}
                setMonthlyOption={setMonthlyOption}
                />
                </Form>
            </Modal.Body>
            <Modal.Footer>
              
                <Button variant="danger" onClick={closeModal}>
                Cancel
                </Button>
                <Button variant="dark" onClick={createMeeting}>
                Schedule
                </Button>
                <Spinner id='spinner' animation="border" role="status" hidden={!loading}> </Spinner>
            </Modal.Footer>
            </Modal>
        </div>
    );

    function RecurrenceInput(props) {

        const { recurrence, 
            setRecurrence, 
            endDateTime, 
            setEndDateTime,
            type,
            setType,
            repeatInterval,
            setRepeatInterval,
            monthlyDay,
            setMonthlyDay,
            monthlyWeek,
            setMonthlyWeek,
            weeklyDays,
            setWeeklyDays,
            monthlyWeekDay,
            setMonthlyWeekDay,
            monthlyOption,
            setMonthlyOption
        } = props;
      
        const handleTypeChange = (e) => {
          setType(parseInt(e.target.value));
          setRecurrence((prev) => ({
            ...prev,
            type: parseInt(e.target.value),
          }));
        };
      
        const handleRepeatIntervalChange = (e) => {
          setRepeatInterval(parseInt(e.target.value));
          setRecurrence((prev) => ({
            ...prev,
            repeatInterval: parseInt(e.target.value),
          }));
        };
      
        const handleMonthlyDayChange = (e) => {
          setMonthlyDay(parseInt(e.target.value));
          setRecurrence((prev) => ({
            ...prev,
            monthly_day: parseInt(e.target.value),
          }));
        };
      
        const handleMonthlyWeekChange = (e) => {
          setMonthlyWeek(parseInt(e.target.value));
          setRecurrence((prev) => ({
            ...prev,
            monthly_week: parseInt(e.target.value),
          }));
        };
      
        const handleWeeklyDaysChange = (e) => {
          const value = parseInt(e.target.value);
          setWeeklyDays((prev) =>
            prev.includes(value)
              ? prev.filter((day) => day !== value)
              : [...prev, value]
          );
          setRecurrence((prev) => ({
            ...prev,
            weekly_days: weeklyDays.includes(value)
              ? prev.weekly_days.filter((day) => day !== value)
              : [...prev.weekly_days, value],
          }));
        };
      
        const handleMonthlyWeekDayChange = (e) => {
          setMonthlyWeekDay(parseInt(e.target.value));
          setRecurrence((prev) => ({
            ...prev,
            monthly_week_day: parseInt(e.target.value),
          }));
        };
      
        const handleMonthlyOptionChange = (e) => {
          setMonthlyOption(e.target.value);
        };
      
        const getWeekDayName = (dayNumber) => {
          const daysOfWeek = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ];
          return daysOfWeek[dayNumber - 1];
        };
      
        const getOrdinalSuffix = (number) => {
          const lastDigit = number % 10;
          const lastTwoDigits = number % 100;
      
          if (lastDigit === 1 && lastTwoDigits !== 11) {
            return `${number}st`;
          } else if (lastDigit === 2 && lastTwoDigits !== 12) {
            return `${number}nd`;
          } else if (lastDigit === 3 && lastTwoDigits !== 13) {
            return `${number}rd`;
          } else {
            return `${number}th`;
          }
        };
      
        const getRecurrenceDescription = () => {
          if (recurrence === null) {
            return 'The meeting does not recur.';
          }
      
          let description = '';
      
          if (type === 1) {
            description = `Repeat every ${repeatInterval} day${repeatInterval > 1 ? 's' : ''}`;
          } else if (type === 2) {
            const selectedWeekDays = weeklyDays.map((day) => getWeekDayName(day));
            description = `Repeat every ${repeatInterval} week${repeatInterval > 1 ? 's' : ''} on ${selectedWeekDays.join(', ')}`;
          } else if (type === 3) {
            description = `Repeat every ${repeatInterval} month${repeatInterval > 1 ? 's' : ''}`;
      
            if (monthlyOption === 'date') {
                description += ` on the ${getOrdinalSuffix(monthlyDay)} day of the month`;
            } else {
              const ordinalSuffix = monthlyWeek === -1 ? 'last' : getOrdinalSuffix(monthlyWeek);
              description += ` on the ${ordinalSuffix} ${getWeekDayName(monthlyWeekDay)} of the month`;
            }
          }
      
          return description;
        };

        const handleRecurrenceChange = (e) => {
            if (e.target.value === 'none') {
              setRecurrence(null);
            } else {
              setRecurrence({
                  type: 1,
                  repeatInterval: 1,
                  monthly_day: 1,
                  monthly_week: 1,
                  weekly_days: [1],
                  monthly_week_day: 1
              })
            }
          };

          const handleSetEndDateTime = (date) => {
            setEndDateTime(date);
          }
      
        return (
          <div>
            <Form.Group controlId="recurrenceInput">             
            <Form.Label style={{ fontWeight: 'bold' }}>
            {getRecurrenceDescription()}
            </Form.Label>
              <Form.Check
                type="radio"
                label="None"
                name="recurrenceOptions"
                id="recurrenceNone"
                value="none"
                checked={recurrence === null}
                onChange={handleRecurrenceChange}
              />
              <Form.Check
                type="radio"
                label="Recurring"
                name="recurrenceOptions"
                id="recurrenceRecurring"
                value="recurring"
                checked={recurrence !== null}
                onChange={handleRecurrenceChange}
              />
            </Form.Group>
      
            {recurrence !== null && (
              <div>
                <Form.Group controlId="datePicker">
                    <Form.Label className='form-label'>End Date</Form.Label>
                    <DatePicker
                        selected={endDateTime}
                        onChange={handleSetEndDateTime}
                        dateFormat="yyyy-MM-dd"
                        className='form-date-picker'
                    />
                </Form.Group>
                
                <Form.Group controlId="recurrenceTypeInput">
                  <Form.Label>Recurrence Type</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Daily"
                    name="recurrenceTypeOptions"
                    id="recurrenceTypeDaily"
                    value={1}
                    checked={type === 1}
                    onChange={handleTypeChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Weekly"
                    name="recurrenceTypeOptions"
                    id="recurrenceTypeWeekly"
                    value={2}
                    checked={type === 2}
                    onChange={handleTypeChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Monthly"
                    name="recurrenceTypeOptions"
                    id="recurrenceTypeMonthly"
                    value={3}
                    checked={type === 3}
                    onChange={handleTypeChange}
                  />
                </Form.Group>
      
                <Form.Group controlId="repeatIntervalInput">
                  <Form.Label>Repeat Every</Form.Label>
                  <Form.Control
                    type="number"
                    min="1"
                    value={repeatInterval}
                    onChange={handleRepeatIntervalChange}
                  />
                </Form.Group>
      
                {type === 3 && (
                  <div>
                    <Form.Group controlId="monthlyOptionInput">
                      <Form.Label>Monthly Option</Form.Label>
                      <Form.Check
                        type="radio"
                        label="By Date"
                        name="monthlyOptionOptions"
                        id="monthlyOptionDate"
                        value="date"
                        checked={monthlyOption === 'date'}
                        onChange={handleMonthlyOptionChange}
                      />
                      <Form.Check
                        type="radio"
                        label="By Week and Day"
                        name="monthlyOptionOptions"
                        id="monthlyOptionWeekDay"
                        value="week"
                        checked={monthlyOption === 'week'}
                        onChange={handleMonthlyOptionChange}
                      />
                    </Form.Group>
      
                    {monthlyOption === 'date' && (
                      <Form.Group controlId="monthlyDayInput">
                      <Form.Label>Monthly Day</Form.Label>
                      <Form.Control as="select" value={monthlyDay} onChange={handleMonthlyDayChange}>
                        {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                          <option key={day} value={day}>{day}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    )}
      
                    {monthlyOption === 'week' && (
                      <div>
                        <Form.Group controlId="monthlyWeekInput">
                        <Form.Label>Monthly Week</Form.Label>
                        <Form.Control as="select" value={monthlyWeek} onChange={handleMonthlyWeekChange}>
                            <option value="-1">Last Week</option>
                            <option value="1">First Week</option>
                            <option value="2">Second Week</option>
                            <option value="3">Third Week</option>
                            <option value="4">Fourth Week</option>
                        </Form.Control>
                        </Form.Group>
      
                        <Form.Group controlId="monthlyWeekDayInput">
                        <Form.Label>Monthly Week Day</Form.Label>
                        <Form.Control as="select" value={monthlyWeekDay} onChange={handleMonthlyWeekDayChange}>
                            <option value="1">Sunday</option>
                            <option value="2">Monday</option>
                            <option value="3">Tuesday</option>
                            <option value="4">Wednesday</option>
                            <option value="5">Thursday</option>
                            <option value="6">Friday</option>
                            <option value="7">Saturday</option>
                        </Form.Control>
                        </Form.Group>
                      </div>
                    )}
                  </div>
                )}
      
                {type === 2 && (
                  <Form.Group controlId="weeklyDaysInput">
                    <Form.Label>Weekly Days</Form.Label>
                    {[1, 2, 3, 4, 5, 6, 7].map((day) => (
                      <Form.Check
                        key={day}
                        type="checkbox"
                        label={getWeekDayName(day)}
                        value={day}
                        checked={weeklyDays.includes(day)}
                        onChange={handleWeeklyDaysChange}
                      />
                    ))}
                  </Form.Group>
                )}
              </div>
            )}
          </div>
        );
      }
}


  export default AvailableSlotsView;